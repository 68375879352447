import { getProperty, updateProperty } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export const DOSSIER_COMMON_INPUTS = [
  {
    component: 'div',
    class: 'tw-max-w-xl',
    children: [
      {
        component: 'h2',
        children: 'Algemeen'
      },
      {
        type: 'checkbox',
        name: 'allow_automated_communication',
        label: 'Automatische communicatie aan eigenaar toestaan'
      },
      {
        component: 'h2',
        children: 'Mandaat'
      },
      {
        type: 'date',
        name: 'mandate_signature_date',
        label: 'Datum ondertekening opdracht',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        type: 'date',
        name: 'mandate_end_date',
        label: 'Datum einde opdracht',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        type: 'date',
        name: 'termination_letter_reception_date',
        label: 'Datum ontvangst opzegbrief',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD',
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'termination_reason',
        label: 'Reden van opzeg',
        placeholder: 'Reden van opzeg',
        options: [] // Options filled async
      },
      {
        type: 'textarea',
        name: 'termination_reason_info',
        label: 'Reden van opzeg - extra informatie'
      }
    ]
  }
]

export const actions = {
  async load (propertyId) {
    try {
      const response = await getProperty(propertyId)
      response.data.formContext = 'dossier'
      response.data.rental_term = response.data.rental_term?.id
      response.data.termination_reason = response.data.termination_reason?.id
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van het dossier, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      // All GET response data is included in the payload (not desired, but it is how it works)
      // The PATCH endpoint, however, doesn't the like the the objects `city`, `niche` & `type`
      delete data.city
      delete data.niche
      delete data.notary_of_buyers
      delete data.notary_of_owners
      delete data.syndic
      delete data.type
      delete data.syndic
      const response = await updateProperty(propertyId, data)
      successModal('De wijzigingen aan het dossier zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van het dossier. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}
