import { DOSSIER_COMMON_INPUTS, actions } from './dossier'
import { YES_NO_BOTH } from '@/forms/schemas/generators'

const schema = [
  ...DOSSIER_COMMON_INPUTS,
  {
    component: 'div',
    class: 'tw-max-w-xl',
    children: [
      {
        component: 'h2',
        children: 'Compromis'
      },
      {
        type: 'date',
        name: 'agreement_signature_date',
        label: 'Datum ondertekening',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        component: 'h2',
        children: 'Voorschot'
      },
      {
        type: 'number',
        name: 'advance_payment',
        label: 'Voorschot',
        placeholder: 'Voorschot',
        validation: 'bail|optional|min:0',
        step: '0.01'
      },
      {
        type: 'select',
        name: 'advance_payment_account',
        label: 'Betaling voorschot op rekening',
        placeholder: 'Betaling voorschot op rekening',
        options: {
          1: 'Dewaele',
          2: 'Notaris'
        }
      },
      {
        type: 'checkbox',
        name: 'should_deduct_sales_fee_from_advance_payment',
        label: 'Afhouding ereloon van voorschot',
        placeholder: 'Afhouding ereloon van voorschot'
      },
      {
        component: 'h2',
        children: 'Akte'
      },
      {
        type: 'date',
        name: 'deed_date',
        label: 'Datum verlijden',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        type: 'checkbox',
        name: 'is_deed_signed',
        label: 'Akte is doorgegaan'
      },
      {
        component: 'h2',
        children: 'Overige (verkoopdossier)'
      },
      {
        name: 'sale_with_shares',
        label: 'Verkoop onder aandelen',
        outerClass: 'tw-m-0',
        ...YES_NO_BOTH
      }
    ]
  }
]

export default {
  heading: 'Dossier',
  schema,
  actions
}
